import React from "react";
import "./Footer.css";
import { FaFacebookF, FaInstagram, FaYoutube, FaEnvelope, FaPhone, FaMapMarkerAlt } from "react-icons/fa";
import footerLogo from '../../images/footerLogo.png'
const Footer = () => {
    return (
        <footer className="footer">

            {/* <div className="footer-container">
                <div className="footer-section">
                    <div className="footer-logo-container">
                        <img src={footerLogo} alt="Logo" className="footer-logo-img" />
                    </div>
                    <strong>
                        Our Core Values
                    </strong>
                    <ul className="footer-links1">
                        <li>Integrity & Transparency</li>
                        <li>Excellence & Efficiency</li>
                        <li>Ethical Recruitment Practices</li>
                        <li>Client & Candidate Satisfaction</li>
                    </ul>


                </div>

                <div className="footer-section">
                    <h3 className="footer-title">Quick Links</h3>
                    <ul className="footer-links">
                        <li><a href="#">About</a></li>
                        <li><a href="#">Employers</a></li>
                        <li><a href="#">Job Seekers</a></li>
                        <li><a href="#">Blog</a></li>
                        <li><a href="#">Contact</a></li>
                    </ul>
                </div>

                <div className="footer-section contact-info">
                    <div className="contact-item">
                        <FaEnvelope className="icon" />
                        <span>email@email.com</span>
                    </div>
                    <div className="contact-item">
                        <FaPhone className="icon" />
                        <span>+91 91416 91416</span>
                    </div>
                    <div className="contact-item">
                        <FaMapMarkerAlt className="map-icon" />
                        <span> 2nd Floor, Masjid Complex, Mattuthavani Main Rd, opposite Omni Bus Stand, Madurai, Tamil Nadu 625007</span>
                    </div>

                    <div className="social-icons">
                        <a href="#" className="social-icons-face"><FaFacebookF /></a>
                        <a href="#" className="social-icons-insta"><FaInstagram /></a>
                        <a href="#" className="social-icons-you"><FaYoutube /></a>
                    </div>
                </div>
            </div> */}

            <div className="footer-container">
                <div className="footer-row">
                    {/* Logo & About */}
                    <div className="footer-column">
                        <div className="footer-logo-container">
                            <img src={footerLogo} alt="Logo" className="footer-logo-img" />
                        </div>
                        <strong>Our Core Values</strong>
                        <ul className="footer-links1">
                            <li>Integrity & Transparency</li>
                            <li>Excellence & Efficiency</li>
                            <li>Ethical Recruitment Practices</li>
                            <li>Client & Candidate Satisfaction</li>
                        </ul>
                    </div>

                    {/* Quick Links */}
                    <div className="footer-column">
                        <h3 className="footer-title">Quick Links</h3>
                        <ul className="footer-links">
                            <li><a href="#">About</a></li>
                            <li><a href="#">Employers</a></li>
                            <li><a href="#">Job Seekers</a></li>
                            <li><a href="#">Blog</a></li>
                            <li><a href="#">Contact</a></li>
                        </ul>
                    </div>

                    {/* Contact Info */}
                    <div className="footer-column contact-info">
                        <div className="contact-item">
                            <FaEnvelope className="icon" />
                            <span>info@tizconsultancy.com</span>
                        </div>
                        <div className="contact-item">
                            <FaPhone className="icon" />
                            <span>+91 91416 91416</span>
                        </div>
                        <div className="contact-item">
                            <FaMapMarkerAlt className="map-icon" />
                            <span>2nd Floor, Masjid Complex, Mattuthavani Main Rd, opposite Omni Bus Stand, Madurai, Tamil Nadu 625007</span>
                        </div>
                    </div>
                </div>
                {/* Social Media Icons */}
                <div className="footer-row social-icons">
                    <a href="#" className="social-icons-face"><FaFacebookF /></a>
                    <a href="#" className="social-icons-insta"><FaInstagram /></a>
                    <a href="#" className="social-icons-you"><FaYoutube /></a>
                </div>
            </div>

            {/* Footer Bottom */}
            <div className="footer-bottom">
                Brand | All Rights Reserved 2025 | <a href="#">Privacy Policy</a> | <a href="#">Terms of Use</a> |
                Powered with ❤️ by TizConsultancy.com
            </div>
        </footer>
    );
};

export default Footer;

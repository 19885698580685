import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import commonEn from "../../utils/locales/en/common.json";
import commonMl from "../../utils/locales/ml/common.json";
import commonTa from "../../utils/locales/ta/common.json";
import commonHi from "../../utils/locales/hi/common.json";
import commonKn from "../../utils/locales/kn/common.json";
import commonTe from "../../utils/locales/te/common.json";

export const getLanguageFromLocalStorage = () => {
  return localStorage.getItem("selectedLanguage") || "en";
};

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: commonEn,
    },
    ml: {
      translation: commonMl,
    },
    ta: {
      translation: commonTa,
    },
    hi: {
      translation: commonHi,
    },
    kn: {
      translation: commonKn,
    },
    te: {
      translation: commonTe,
    }
  },
  lng: getLanguageFromLocalStorage(), // Default language
  fallbackLng: "en", // Fallback language if the selected one is not available
  interpolation: {
    escapeValue: false, // React already escapes values
  },
});

export default i18n;

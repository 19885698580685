import React from "react";
import "./Services.css";
import { FaBuilding, FaUserTie, FaPassport, FaPlane, FaBalanceScale, FaBriefcase, FaChalkboardTeacher, FaHeadset } from "react-icons/fa";

const Services = () => {
  return (
    <div className="services-section">
      <h2 className="section-title">Our Services</h2>
      <div className="services-container">
        <div className="services-box">
          <h3>For Employers</h3>
          <ul>
            <li><FaBuilding className="services-box-icon" /> Sourcing skilled workers based on industry requirements</li>
            <li><FaUserTie className="services-box-icon" /> Screening and selection process</li>
            <li><FaPassport className="services-box-icon" /> Visa processing and documentation</li>
            <li><FaPlane className="services-box-icon" /> Deployment and onboarding assistance</li>
            <li><FaBalanceScale className="services-box-icon" /> Compliance with labor laws and ethical hiring standards</li>
          </ul>
        </div>

        <div className="services-box">
          <h3>For Job Seekers</h3>
          <ul>
            <li><FaBriefcase className="services-box-icon" /> Overseas job placement opportunities</li>
            <li><FaPassport className="services-box-icon" /> Assistance with visa applications and work permits</li>
            <li><FaChalkboardTeacher className="services-box-icon" /> Pre-departure training and orientation</li>
            <li><FaHeadset className="services-box-icon" /> Continuous support and career guidance</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Services;

import React from "react";
import "./Banner.css";

const Banner = () => {
  return (
    <div className="banner">
      {/* Job Seeker Section */}
      <div className="banner-section job-seeker">
        <div className="overlay"></div>
        <div className="content">
          <p>I am a job seeker</p>
          <h1>LOOKING <br /> FOR A JOB</h1>   
          <button className="apply-btn">Apply Now</button>
      
        </div>
      </div>

      {/* Employer Section */}
      <div className="banner-section employer">
        <div className="overlay"></div>
        <div className="content">
          <p>I am an employer</p>
          <h1>MANAGING <br /> A WORKPLACE</h1>
          <button className="apply-btn">Apply Now</button>
        </div>
      </div>
    </div>
  );
};

export default Banner;

import React from "react";
import "./Blogs.css";

import blog1 from "../../images/blog1.jpg";
import blog2 from "../../images/blog2.jpg";
import blog3 from "../../images/blog3.jpg";
import blog4 from "../../images/blog4.jpg";
import blog5 from '../../images/blog5.jpg'
import blog6 from '../../images/blog6.jpg'
import blog7 from '../../images/blog7.jpg'
import blog8 from '../../images/blog8.jpg'
import blog9 from '../../images/blog9.jpg'
import blog10 from '../../images/blog10.jpg'

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Blogs = () => {

    const blogs = [
        {
            id: 1,
            title: "How to Secure a High-Paying Blue-Collar Job Abroad",
            description:
                "Overview of industries hiring abroad (construction, manufacturing, hospitality, etc.).Steps to apply for overseas jobs.Skills and certifications that increase hiring chances.",
            image: blog1,
        },
        {
            id: 2,
            title: "Top Destinations for Blue-Collar Workers in 2025",
            description:
                "Countries with high demand for skilled labor.Salary expectations and benefits.Work culture and living conditions.",
            image: blog2,
        },
        {
            id: 3,
            title: "Visa and Documentation Guide for Overseas Workers",
            description:
                "Essential documents required (passport, visa, work permit).Step-by-step guide to visa application.Common mistakes to avoid.",
            image: blog3,
        },

        {
            id: 4,
            title: "How to Avoid Fraud When Applying for Jobs Abroad",
            description:
                "Red flags of fake recruitment agencies.How to verify job offers and employers.Importance of working with a registered consultancy.",
            image: blog4,
        },

        {
            id: 5,
            title: "Training & Skill Development for Overseas Jobs",
            description:
                "Certifications that boost employability.Language skills for working abroad.Free and paid training programs.",
            image: blog5,
        },

        {
            id: 6,
            title: "Life as an Overseas Worker: Challenges & Rewards",
            description:
                "Real-life experiences from migrant workers.Overcoming cultural and language barriers.Financial growth and career advancement.",
            image: blog6,
        },

        {
            id: 7,
            title: "How to Prepare for a Job Interview with Foreign Employers",
            description:
                "Common interview questions.Dress code and etiquette.How to impress recruiters.",
            image: blog7,
        },

        {
            id: 8,
            title: "Understanding Overseas Work Contracts & Employee Rights",
            description:
                "Key clauses to check in a job contract.Labor laws and worker rights in different countries.What to do in case of contract disputes.",
            image: blog8,
        },

        {
            id: 9,
            title: "Managing Finances While Working Abroad",
            description:
                "Best ways to send money home.Savings and investment tips.Budgeting for life abroad.",
            image: blog9,
        },

        {
            id: 10,
            title: "Post-Employment Guide: Returning Home After Working Abroad",
            description:
                "How to transition back home smoothly.Career options after returning.Government schemes for returning workers.",
            image: blog10,
        },
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        padding: 0,
        margin: 0,
        responsive: [
            { breakpoint: 1024, settings: { slidesToShow: 2 } },
            { breakpoint: 600, settings: { slidesToShow: 1 } },
        ],
    };

    return (
        <div className="blog-section">
            <h2>Blog or social feeds here</h2>
            <div className="blog-carousel-container">
                <Slider {...settings}>
                    {blogs.map((blog) => (
                        <div key={blog.id} className="blog-card">
                            <img src={blog.image} alt={blog.title} />
                            <div className="blog-content">
                                <h3>{blog.title}</h3>
                                <p>{blog.description}</p>
                                <a href="#">Read more →</a>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>

        </div>
    )

}

export default Blogs
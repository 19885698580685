import React from "react";
import "./Testimonials.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Testimonials.css";

const TestimonialCard = ({ text, rating, name }) => {
  return (
    <div className="testimonial-card">
      <p className="testimonial-text">{text}</p>
      <div className="stars">
        {"★".repeat(rating)}{"☆".repeat(5 - rating)}
      </div>
      <p>{name}</p>

    </div>
  );
};


const Testimonials = () => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: true, /* Helps remove gaps */
    centerPadding: "0px", /* Ensure no padding */
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <div>

      {/* <div className="how-it-works-container">
        <h1>How It Works</h1>
        <div className="steps-list">
          <h2>1. Register & Submit Your CV</h2>
          <h2>2. Attend Employer Interviews</h2>
          <h2>3. Receive Job Offer & Process Visa</h2>
          <h2>4. Fly to Your New Job Abroad</h2>
        </div>
        <div className="image-container">
          <img src={howItWorksImage} alt="How It Works" className="how-it-works-image" />
        </div>
        <button className="apply-button">APPLY NOW</button>
      </div> */}

      <div className="testimonials-container">
        <h1 className="testimonials-heading">What People say about us!</h1>
        <Slider {...settings}>
          <TestimonialCard
            text="Thanks to TIZ Consultancy, I secured a stable job overseas. The process was smooth and hassle-free!"
            rating={5}
            name='Krishnan, Trailer Driver'
          />
          <TestimonialCard
            text="I never thought working abroad would be this easy. Thanks to TIZ Consultancy, I got a great job with a reliable employer!"
            rating={4}
            name='Rajesh K, Electrician'
          />
          <TestimonialCard
            text="The recruitment process was fast, and they guided me through every step. Now I am working in a top company overseas."
            rating={5}
            name='Ahmed S, Security Guard'
          />
          <TestimonialCard
            text="I am grateful for the support I received. From visa processing to job placement, everything was handled smoothly."
            rating={4}
            name="Mohammed Toufiq, Cleaner"
          />

          <TestimonialCard
            text="The training provided before leaving was very helpful. I was well-prepared for my job overseas, and I appreciate the assistance."
            rating={5}
            name="John, Forklift Operator"
          />

          <TestimonialCard
            text="I was worried about the visa process, but the team at TIZ Consultancy made it stress-free. I am now happily employed abroad."
            rating={4}
            name="Ramesh, Driver"
          />

          <TestimonialCard
            text="This consultancy changed my life. I secured a stable job and can now support my family. Thank you for your dedication!"
            rating={5}
            name="Hanuman Reddy, Security Guard"
          />
        </Slider>
      </div>
    </div>

  );
};

export default Testimonials;

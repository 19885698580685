import React, { useState, useEffect, useRef } from "react";
import "./Sector.css";

const Counter = ({ end, duration, suffix = "" }) => {
  const [count, setCount] = useState(0);
  const counterRef = useRef(null);
  const [hasStarted, setHasStarted] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setHasStarted(entry.isIntersecting); // Reset animation every time it's visible
      },
      { threshold: 0.5 }
    );
  
    if (counterRef.current) {
      observer.observe(counterRef.current);
    }
  
    return () => {
      if (counterRef.current) {
        observer.unobserve(counterRef.current);
      }
    };
  }, []);
  

  useEffect(() => {
    if (!hasStarted) return;

    let start = 0;
    const increment = end / (duration * 60);
    const interval = setInterval(() => {
      start += increment;
      if (start >= end) {
        clearInterval(interval);
        setCount(end);
      } else {
        setCount(Math.ceil(start));
      }
    }, 16);

    return () => clearInterval(interval);
  }, [hasStarted, end, duration]);


  return <span ref={counterRef} className="stat-value">{count}{suffix}</span>;
};

export default Counter;

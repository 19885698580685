import React, { useState } from "react";
import "./Contact.css"; // Import CSS file
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const ContactUs = () => {


    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        message: "",
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        alert("Form submitted!");
    };

    return (
        <div>
            <Header />
            
            <div className="contact-us-section">
                <h1 className="contact-title">Contact Us</h1>
            </div>

            <div className="contact-form-container">
                <form onSubmit={handleSubmit} className="contact-form">
                    <label>Name:</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />

                    <label>Email:</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />

                    <label>Phone:</label>
                    <input
                        type="tel"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                    />

                    <label>Message:</label>
                    <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                    ></textarea>

                    <button type="submit">Send</button>
                </form>
            </div>


            <Footer />

        </div>

    );
};

export default ContactUs;

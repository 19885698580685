import React from "react";
import OwlCarousel from "react-owl-carousel3";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";


import "../Clients/Client.css";

import client from '../../images/client.png'
import client1 from '../../images/client1.png'
import client2 from '../../images/client2.png'
import client3 from '../../images/client3.png'
import client4 from '../../images/client4.png'
import client5 from '../../images/client5.png'
import client6 from '../../images/client6.png'
import client7 from '../../images/client7.png'
import client8 from '../../images/client8.png'
import client9 from '../../images/client9.png'
import client10 from '../../images/client10.png'
import client11 from '../../images/client11.png'
import client12 from '../../images/client12.png'
import client13 from '../../images/client13.png'
// import client14 from '../../images/client14.png'
import client15 from '../../images/client15.png'
import client16 from '../../images/client16.png'
import client17 from '../../images/client17.png'
import client18 from '../../images/client18.png'
import client19 from '../../images/client19.png'
import client20 from '../../images/client20.png'
import client21 from '../../images/client21.png'
import client22 from '../../images/client22.png'
import client23 from '../../images/client23.png'
import client24 from '../../images/client24.png'
import client25 from '../../images/client25.png'
import client26 from '../../images/client26.png'
import client27 from '../../images/client27.png'

const Client = () => {
    const images = [
        client, client1, client2, client3, client4, client5, client6, client7, client8, client9, client10,
        client11, client12, client13, client15, client16, client17, client18, client19, client20, client21, client22, client23, client24,
        client25, client26, client26, client27,
    ];

 
    return (

        <div className="talent-section">
            <h2>Our Clients</h2>


            <div className="carousel-container">
                <div className="carousel-track">
                    {/* Duplicate images for infinite effect */}
                    {images.map((src, index) => (
                        <div className="logo-item" key={index}>
                            <img src={src} alt={`Client ${index + 1}`} />
                        </div>
                    ))}
                </div>
            </div>

        </div>
    );
};

export default Client;

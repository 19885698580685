import React from "react";
import "./TrainingPlacements.css";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import shapeImage from '../../images/shape.png'
import skillImage from '../../images/skill.jpg'
import safetyImage from '../../images/safety.jpg'
import small1 from '../../images/small1.jpg'
import small2 from '../../images/small2.jpg'
import small3 from '../../images/small3.jpg'


import consulting1 from "../../images/consulting1.jpg"; // Replace with actual image path
import consulting2 from "../../images/consulting2.jpg"; // Replace with actual image path
import consulting3 from "../../images/consulting3.jpg"; // Replace with actual image path

import trainingImage from "../../images/professional.png"; // Ensure the correct path

import develop from '../../images/develop.png'

const TrainingPlacements = () => {

  const trainingPrograms = [
    {
      title: "Workforce Skill Enhancement Training",
      description:
        "Hands-on training in forklift operation, trailer truck driving, and security procedures to meet international job standards.",
    },
    {
      title: "Soft Skills & Communication Training",
      description:
        "Enhances professional communication, active listening, and conflict resolution skills for a productive work environment.",
    },
    {
      title: "Compliance & Workplace Safety Training",
      description:
        "Covers workplace safety protocols, Personal Protective Equipment (PPE) usage, and adherence to international labor laws.",
    },
    {
      title: "Pre-Departure Orientation & Cultural Awareness",
      description:
        "Prepares candidates with professionalism, time management, and adaptability for a smooth transition into the workforce.",
    },
  ];

  return (

    <div>

      <Header />

      <div className="training-section-top">
        {/* Background Section */}
        <div className="training-background-container">
          <div className="text-box">
            <h2>TRAINING &</h2>
            <h2>PLACEMENTS</h2>
          </div>
        </div>
      </div>

      <div className="training-placement">
        <div className="developing-content">
          <p>
            We believe in developing a skilled workforce through structured
            training programs before deployment. Our Training and Placement
            Program in India is designed to enhance the technical and soft skills
            of workers, ensuring they are well-prepared for overseas assignments.
          </p>
        </div>
        <div className="shapes">
          <img src={shapeImage} alt="Shapes" className="shape-image" />
        </div>
      </div>

      <div className="training-container">
        <div className="training-content">
          <div className="training-left">
            <img src={skillImage} alt="Training Session" className="training-image" />
          </div>
          <div className="training-right">
            <h2 className="training-title">How Our Training Program Works Skill Development</h2>
            <p className="training-text">Hands-on training in industry-specific skills to meet international job requirements.</p>
          </div>
        </div>

      </div>

      <div className="training-container1">
        <div className="training-content1">

          <div className="training-right1">
            <h2 className="training-title1">Training safety & Compliance</h2>
            <p className="training-text1">Education on workplace safety standards and labor laws to ensure
              adherence to regulations.</p>
          </div>

          <div className="training-left1">
            <img src={safetyImage} alt="Training Session" className="training-image1" />

          </div>

        </div>
      </div>

      <div className="sub-images">
        <img src={small1} alt="Evaluation" className="small-image" />
        <img src={small2} alt="Form" className="small-image" />
        <img src={small3} alt="Document Review" className="small-image" />
      </div>

      <div className="communication-section">
        <div className="communication-content">
          <h2>Language & Communication Training</h2>
          <p>
            Basic language proficiency and communication skills for better
            workplace integration.
          </p>

          <h2>Practical Assessments</h2>
          <p>Real-world simulations and tests to evaluate worker readiness.</p>

          <h2>Certification</h2>
          <p>
            Workers receive industry-recognized certification upon completion of
            training.
          </p>
        </div>
        <div className="communication-circle"></div>
      </div>

      <div className="consulting-section">
        <div className="consulting-left">
          <img src={consulting1} alt="Consulting Meeting" className="consulting-large-image" />
        </div>
        <div className="consulting-right">
          <div className="consulting-text-box">
            <h2>Consulting</h2>
            <p>
              We equip job seekers with prospective training on their
              industry-specific skills, workplace safety protocols, and technical
              knowledge to meet global job requirements.
            </p>
          </div>
          <div className="consulting-images">
            <img src={consulting2} alt="Meeting" className="consulting-small-image" />
            <img src={consulting3} alt="Work Discussion" className="consulting-small-image" />
          </div>
        </div>
      </div>

      <div className="benefits-section">
        <h2>Benefits of Our Training Program for Employers</h2>
        <ul>
          <li>Workforce is pre-trained, reducing onboarding time and costs.</li>
          <li>Higher productivity and efficiency from day one.</li>
          <li>Compliance with international safety and job standards.</li>
          <li>Reduced risk of worker attrition and turnover.</li>
        </ul>
      </div>

      <div className="training-section">
        <div className="training-section-container">
          {/* Left Image Section */}
          <div className="training-container-image-container">
            <h2 className="section-heading">Professional Development & Workplace Training</h2>

            <img src={trainingImage} alt="Professional Training" />
          </div>

          {/* Right Cards Section */}
          <div className="training-container-cards-container">
            {trainingPrograms.map((program, index) => (
              <div key={index} className="training-container-card">
                <h3>{program.title}</h3>
                <p>{program.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>


      <div className="training-wrapper">
        <div className="training-box">
          <div className="training-photo">
            <img src={develop} alt="Professional Training" />
          </div>
          <div className="training-details">
            <h2>Professional Development & Training Program</h2>
            <p>
              Develops leadership, decision-making, and team management skills.
              Enhances professional communication, email etiquette, and conflict
              resolution.
            </p>
            <p>
              Covers workplace policies, ethical decision-making, and regulatory
              adherence. Focuses on goal-setting, prioritization, and efficiency
              strategies.
            </p>
          </div>
        </div>
      </div>

      <Footer />

    </div>

  );
};

export default TrainingPlacements;

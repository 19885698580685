import React, { useState } from "react";
import "./Header.css";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    // <header className="header">
    //   <div className="nav-container">
    //     <div className="logo-container">
    //       <img src="/logo.png" alt="Logo" className="logo-img" />
    //     </div>
    //     <nav className="nav">
    //       <ul>
    //         <li>
    //           <a href="/">HOME</a>
    //         </li>
    //         <li>
    //           <a href="/training">TRAINING & PLACEMENTS</a>
    //         </li>
    //         <li>
    //           <a href="/contact">CONTACT</a>
    //         </li>
    //       </ul>
    //     </nav>
    //   </div>
    // </header>

    <header className="header">
      <div className="nav-container">
        <div className="logo-container">
          <img src="/logo.png" alt="Logo" className="logo-img" />
        </div>
        <button className="menu-toggle" onClick={() => setMenuOpen(!menuOpen)}>
          ☰
        </button>
        <nav className={`nav ${menuOpen ? "open" : ""}`}>
          <ul>
            <li>
              <a href="/">HOME</a>
            </li>
            <li>
              <a href="/training">TRAINING & PLACEMENTS</a>
            </li>
            <li>
              <a href="/contact">CONTACT</a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;

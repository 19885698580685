import React from "react";
import "./Sector.css";
import image1 from '../../images/security.jpg'
import image2 from '../../images/truck.jpg'
import image3 from '../../images/forklift.jpg'
import building from '../../images/dubai.jpg'
import Testimonials from "../Testimonials/Testimonials";
import sideImage from "../../images/side1.jpg";

import blog1 from "../../images/blog1.jpg";
import blog2 from "../../images/blog2.jpg";
import blog3 from "../../images/blog3.jpg";

import Client from "../Clients/Client";
import Counter from "./Counter";
import Services from "../Services/Services";
import Blogs from "../Blogs/Blogs";


const Sectors = () => {

    const sectors = [
        { title: "Security Guard", image: image1 },
        { title: "Trailer Driver", image: image2 },
        { title: "Forklift Operator", image: image3 },
    ];

    // const jobs = [
    //     {
    //         id: 1,
    //         location: "Sydney",
    //         title: "QA BH Job 0322 v7",
    //         date: "22/03/2024",
    //         description: "QA BH Job 0322 v7",
    //     },
    //     {
    //         id: 2,
    //         location: "Djuan",
    //         title: "QA BH 0321 1113 v2",
    //         date: "21/03/2024",
    //         description: "QA BH 0321 1113 v2",
    //     },
    //     {
    //         id: 3,
    //         location: "Indeed",
    //         title: "TEST dev Job Indeed",
    //         date: "19/06/2024",
    //         description: "QA BH 0321 1113 v2",
    //     },
    // ];

    const blogs = [
        {
            id: 1,
            title: "Make the most of the season by following these simple guidelines",
            description:
                "The new season is a great reason to make and keep resolutions. Whether it’s eating right or cleaning out the garage, here are some tips for making and keeping resolutions.",
            image: blog1,
        },
        {
            id: 2,
            title: "Keep in touch with site visitors and boost loyalty",
            description:
                "There are so many good reasons to communicate with site visitors. Tell them about sales and new products or update them with tips and information.",
            image: blog2,
        },
        {
            id: 3,
            title: "Tips for writing great posts that increase your site traffic",
            description:
                "Write about something you know. If you don’t know much about a specific topic that will interest your readers, invite an expert to write about it.",
            image: blog3,
        },
    ];

    return (
        <div>

            <section className="info-section">
                <div className="info-container">
                    <div className="info-image">
                        <img src={building} alt="Building" />
                    </div>
                    <div className="info-content">
                        <h2 className="info-title">About Us</h2>
                        <p className="info-text">
                            At TIZ Consultancy, we are committed to bridging the gap between skilled workers and reputable employers across the globe. With years of experience in overseas recruitment, we have successfully placed thousands of workers in industries such as Travel & Logistics, Security Guards, construction, manufacturing,  and hospitality.
                            Our mission is to provide ethical, efficient, and professional recruitment services that benefit both employers and job seekers. We ensure compliance with international labor laws and uphold the highest standards of integrity.
                        </p>
                        {/* <button className="info-button">Lorem Ipsum</button> */}
                    </div>
                </div>
            </section>

            <Services />

            <section className="sectors">
                <h2 className="title">Sectors We Work In</h2>
                <div className="sector-container">
                    {sectors.map((sector, index) => (
                        <div
                            key={index}
                            className="sector-card"
                            style={{ backgroundImage: `url(${sector.image})` }}
                        >
                            {/* <div className="button-overlay"> */}
                            <button className="button-overlay">{sector.title}</button>
                            {/* </div> */}
                        </div>
                    ))}
                </div>
            </section>

            {/* <div className="job-list-container">
                <h2 className="job-title">Jobs Feed</h2>
                <div className="job-list">
                    {jobs.map((job) => (
                        <div key={job.id} className="job-card">
                            <p className="job-location">{job.location}</p>
                            <h3 className="job-heading">{job.title}</h3>
                            <p className="job-date">{job.date}</p>
                            {job.description && <p className="job-description">{job.description}</p>}
                            <button className="job-button">
                                Learn More <span>➤</span>
                            </button>
                        </div>
                    ))}
                </div>
            </div> */}

            <Client />

            <Testimonials />

            <section className="info-section">
                <div className="info-container">

                    <div className="info-content">
                        <h2 className="info-title">How It Works</h2>
                        <p className="info-text">
                            Employers submit job requirements.
                            We source, screen, and shortlist candidates.
                            Selected candidates undergo interviews and skill tests.
                            We handle visa processing and documentation.
                            Candidates are deployed to their new job abroad.

                        </p>
                        <br></br>

                        <strong> It’s that simple! Get started today.</strong>
                        <br></br>

                        <button className="info-button">Apply for Jobs</button>
                    </div>

                    <div className="info-image">
                        <img src={sideImage} alt="Building" />
                    </div>
                </div>
            </section>

            <Blogs />
{/* 
            <div className="blog-section">
                <h2>Blog or social feeds here</h2>
                <div className="blog-container">
                    {blogs.map((blog) => (
                        <div key={blog.id} className="blog-card">
                            <img src={blog.image} alt={blog.title} />
                            <div className="blog-content">
                                <h3>{blog.title}</h3>
                                <p>{blog.description}</p>
                                <a href="#">Read more →</a>
                            </div>
                        </div>
                    ))}
                </div>
            </div> */}

            {/* <div className="hero-section">
                <h1>Lorem Ipsum</h1>
                <p>
                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
                    totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae
                    dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit,
                    sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
                </p>
            </div> */}

            <h2 className="achivement-heading">Our Achievements</h2>

            <div className="stats-section">
                <div className="stat-item">
                    {/* <h3>20 years</h3> */}
                    <Counter end={10000} duration={2} suffix="+" />
                    <p>Skilled Workers Successfully Placed Worldwide</p>
                </div>
                <div className="stat-item">
                    {/* <h3>50,000</h3> */}
                    <Counter end={100} duration={2} suffix="+" />
                    <p>Trusted International Employers</p>
                </div>
                <div className="stat-item">
                    <Counter end={99} duration={2} suffix="%" />
                    <p>Success Rate in Visa Approvals</p>
                </div>
                <div className="stat-item">
                    {/* <h3>50+</h3> */}
                    <Counter end={25} duration={2} suffix="+" />
                    <p>Sectors with Active Job Opportunities</p>
                </div>
                <br></br>
            </div>
            
            <div className="stats-section">
                <br></br>

                <div className="stat-item">
                    {/* <h3>20 years</h3> */}
                    <Counter end={20} duration={2} suffix="+" />
                    <p>Years of Expertise in Global Recruitment</p>
                </div>
                <div className="stat-item">
                    {/* <h3>50,000</h3> */}
                    <Counter end={50000} duration={2} suffix="+" />

                    <p>Job Applications Processed Annually</p>
                </div>
                <div className="stat-item">
                    <Counter end={95} duration={2} suffix="%" />
                    <p>Our Employer Satisfaction Rate</p>
                </div>
                <div className="stat-item">
                    {/* <h3>50+</h3> */}
                    <Counter end={5000} duration={2} suffix="+" />
                    <p>Candidates Trained for Overseas Jobs</p>
                </div>
            </div>

        </div>

    );
};

export default Sectors;

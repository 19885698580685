import './App.css';
import ContactUs from './components/Contact/Contact';
import LandingPage from './Pages/LandingPage';
import TrainingPlacements from './components/TrainingPlacements/TrainingPlacements';

import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <LandingPage />
            }
          />

          <Route
            path="/contact"
            element={
              <ContactUs />
            }
          />

          <Route
            path="/training"
            element={
              <TrainingPlacements />
            }
          />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
